import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HamburgerButton from './hamburger-button';
import HamburgerContent from './hamburger-content';

/**
 * @description Renders hamburger menu button and hamburger menu content. Used only for mobile.
 */
class HamburgerMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    handleMenuClick() {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }

    handleLinkClick() {
        this.setState({ isMenuOpen: false });
    }

    render() {
        return (
            <div>
                <HamburgerButton
                    isOpen={this.state.isMenuOpen}
                    onClick={() => this.handleMenuClick()}
                    color="white" />
                <HamburgerContent
                    isOpen={this.state.isMenuOpen}
                    close={() => this.handleMenuClick()}
                    navigation={this.props.navigation} 
                    navigationMain={this.props.navigationMain}/>
            </div>
        );
    }
}

HamburgerMenu.propTypes = {
    navigation: PropTypes.array.isRequired
};

export default HamburgerMenu;
