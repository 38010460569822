import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * @description Renders the hamburger menu button.
 */
class HamburgerButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen ? this.props.isOpen : false,
            color: this.props.color ? this.props.color : 'black',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: nextProps.isOpen });
        }

        if (nextProps.isOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    }

    handleClick() {

        if (this.props.onClick) {
            this.props.onClick();
            
        } else {
            this.setState({ isOpen: !this.state.isOpen });
        }
    }

    render() {
        const styles = {
            mainContainer: {
                position: 'absolute',
                top: '6px',
                right: '5px',
                display: 'flex',
                height: '32px',
                flexDirection: 'row',
                alignItems: 'center',
                outline: 'none',
                border: 'none',
                gap: '3px'
            },
            mainContainerBorder: {
                border: '2px solid #646464',
                borderRadius: '21px',
                top: '9px',
                right: '8px',
                zIndex: 99,
            },
            centreWhenOpen: {
                left: '5px'
            },
            container: {
                position: 'relative',
                zIndex: '99',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                width: '25px',
                color: 'white',
                backgroundColor: 'transparent',
            },
            line: {
                height: '2px',
                width: '20px',
                transition: 'all 0.2s ease',
            },
            lineTop: {
                transform: this.state.isOpen ? 'rotate(45deg)' : 'none',
                transformOrigin: 'top left',
                marginBottom: '5px',
            },
            lineMiddle: {
                opacity: this.state.isOpen ? 0 : 1,
                transform: this.state.isOpen ? 'translateX(-16px)' : 'none',
            },
            lineBottom: {
                transform: this.state.isOpen ? 'translateX(-1px) rotate(-45deg)' : 'none',
                transformOrigin: 'top left',
                marginTop: '5px',
            },
        };

        return (
            <button
                type="button"
                id={"hamburger-menu-button"}
                aria-label="Toggle menu"
                style={this.state.isOpen? {...styles.mainContainer, ...styles.mainContainerBorder} : styles.mainContainer}
                onClick={() => this.handleClick()}>
                    <div style={this.state.isOpen? {...styles.container, ...styles.centreWhenOpen} : styles.container}>
                        <div style={{ ...styles.line, ...styles.lineTop }} className="hamb-line" />
                        <div style={{ ...styles.line, ...styles.lineMiddle }} className="hamb-line" />
                        <div style={{ ...styles.line, ...styles.lineBottom }} className="hamb-line" />
                    </div>
                    <div className='menu-text'>{this.state.isOpen?`Close`:`Menu`}</div>
            </button>
        );
    }
}

HamburgerButton.propTypes = {
    isOpen: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func
};

export default HamburgerButton;
