import React, { Component } from 'react';
import { Link } from 'gatsby';

/**
 * @description Wrapper around MainMenu, SidebarMenu, and FooterMenu components
 */
export default class Menu extends Component  {


    constructor(props){
        super(props);
        this.state = {
          hover: false,
          hoverId: null,
          mobileView: true
        }
    }

    componentDidMount () {
        this.getViewportWidth();
        window.addEventListener('resize', this.getViewportWidth);
    }
    
    componentWillUnmount () {
        window.removeEventListener('resize', this.getViewportWidth);
    }

    getViewportWidth = () => {
      const innerWidth = Math.min(window.innerWidth, window.screen.width);
      const mobileView = innerWidth < 1150;
      this.setState({ mobileView });
    };

    hoverOn = (id) => {
      this.setState({ hover: true, hoverId: id });
    }

    hoverOff = () => { 
      this.setState({ hover: false, hoverId: null });    
    }

    handleKeyDown = (e,drupal_internal__id) => {
        if(e.key === 'Enter' || e.key === ' ') {
            if(this.state.hover && this.state.hoverId === drupal_internal__id) {
                this.hoverOff()
            } else {
                this.hoverOff()
                this.hoverOn(drupal_internal__id)
            }
            e.preventDefault();
        }
        if(e.key === 'Escape') {
            this.hoverOff()
        }
    }

    handleSublinkKeyDown = (e) => {
        if(e.key === 'Escape') {
            this.hoverOff()
            e.preventDefault()
        }
    }

    handleMobileMouseDown = (e, drupal_internal__id) => {
        if(this.state.hover && this.state.hoverId === drupal_internal__id) {
            this.hoverOff()
        } else {
            this.hoverOff()
            this.hoverOn(drupal_internal__id)
        }
        e.preventDefault();
    }

    handleMobileSublinkKeydown = (e,keyOptional, childrenLengthOptional) => {
        if(e.key === 'Tab') {
            if(e.shiftKey && keyOptional===0) {
                this.hoverOff()
            } else if(!e.shiftKey && keyOptional === childrenLengthOptional-1 ) {
                this.hoverOff()
            }
        }
        if(e.key === 'Escape') {
            this.hoverOff()
        }   
    }

    handleOnClick = (drupal_internal__id) => {
        if(this.state.hover && this.state.hoverId === drupal_internal__id) {
            this.hoverOff()
        } else {
            this.hoverOff()
            this.hoverOn(drupal_internal__id)
        }
    }

    /**
     * @description Converts flat menu array into tree structure.
     * Because we can't make sure parent always is before child (cause we're sorting by weight in graphql)
     * This has to be O(n^2) + O(n log n) sorting of children
     *
     * @param edges
     * @returns {Array}
     */
    convertToTree(edges) {
        var tree = [],
            mappedArr = {},
            arrElem,
            mappedElem;

        // First map the nodes of the array to an object -> create a hash table.
        for (var i = 0, len = edges.length; i < len; i++) {
            arrElem = edges[i].node;
            mappedArr[arrElem.drupal_internal__id] = arrElem;
            mappedArr[arrElem.drupal_internal__id]['children'] = [];
        }


        for (var id in mappedArr) {
            if (mappedArr.hasOwnProperty(id)) {
                mappedElem = mappedArr[id];
                // If the element is not at the root level, add it to its parent array of children.
                if (mappedElem.parent_drupal_internal__id) {
                    mappedArr[mappedElem['parent_drupal_internal__id']]['children'].push(mappedElem);
                }
                // If the element is at the root level, add it to first level elements array.
                else {
                    tree.push(mappedElem);
                }
            }
        }

        // Sort children
        tree.forEach((treeItem) => {
            if (treeItem.children.length === 0)
                return;

            treeItem.children.sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0))
        });

        return tree;
    }


    /**
     * @description Render single menu item
     * @param node
     * @param keyOptional
     * @returns {*}
     */
    renderLink(node, keyOptional, childrenLength) {
        const className = (node.children && node.children.length > 0) ? 'nav-link-expandable' : '';
        const activeClassName = (node.children && node.children.length > 0) ? 'nav-link-2-active' : 'nav-link-active';
        const ariaExpanded = (this.state.hoverId === node.drupal_internal__id) ? true: false;

        const key = ((keyOptional) ? keyOptional : node.drupal_internal__id) * 198;

        //handle no link scenario from Drupal
        if(node.alias === null) {
            if(this.state.mobileView) {
                return (<button type='button' aria-expanded={ariaExpanded} className={className} key={key} onMouseDown={(e)=>this.handleMobileMouseDown(e, node.drupal_internal__id)} onKeyDown={(e)=>this.handleKeyDown(e, node.drupal_internal__id)}>{node.title}</button>)
            } else {
                return (<button type='button' aria-expanded={ariaExpanded} onMouseEnter={() => this.hoverOn(node.drupal_internal__id)} onClick={() => this.handleOnClick(node.drupal_internal__id)} className={className} key={key} onKeyDown={(e)=>this.handleKeyDown(e, node.drupal_internal__id)}>{node.title}</button>);
            }
        }

        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(node.alias);
        
        if (internal) {  
            if (this.state.mobileView) {
                if(node.children && node.children.length === 0) {
                    return (<Link activeClassName={activeClassName} onKeyDown={(e)=>this.handleMobileSublinkKeydown(e,keyOptional, childrenLength, node.title)} className={className} key={key} to={node.alias}>{node.title}</Link>);
                }
                return (<Link activeClassName={activeClassName} className={className} key={key} to={node.alias}>{node.title}</Link>);               
            }

            if(node.children && node.children.length === 0)
            {       
                return (<Link activeClassName={activeClassName} onBlur={()=>{}} onKeyDown={(e) => this.handleSublinkKeyDown(e)} className={className} key={key} to={node.alias}>{node.title}</Link>);
            }          
            return (
                <Link onMouseEnter={() => this.hoverOn(node.drupal_internal__id)} activeClassName={activeClassName} className={className} key={key} to={node.alias}>{node.title}</Link>
            );
        }
        //for external links
        if(this.state.mobileView) {
            return <a className={className} key={key} target="_blank" rel="noopener noreferrer" onKeyDown={(e)=>this.handleMobileSublinkKeydown(e,keyOptional, childrenLength)} href={node.alias}>{node.title}</a>
        } 
        return (
            <a className={className} key={key} target="_blank" rel="noopener noreferrer" onKeyDown={(e)=>this.handleMobileSublinkKeydown(e,keyOptional, childrenLength)} href={node.alias}>{node.title}</a>
        );
    }
};
