import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Menu from '../menus/menu';
import HamburgerMenu from './hamburger-menu';
import { connect } from "react-redux"
import * as types from '../constants/ActionTypes';

/**
 * @description Renders main navigation
 */
class MainMenu extends Menu {

    constructor(props){
        super(props);
        this.state = {
          hover: false,
          hoverId: null
        };
    }

    handleClick = (id) => {
        this.setState({ hover: !this.state.hover, hoverId: id });
    }

    handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            this.hoverOff()
        }
    }

    /**
     * @description Render Links With Children
     * @param node
     * @returns {*}
     */
    renderLinksWithChildren(node){
        const children = node.children;
        if(children.length === 0)
            return this.renderLink(node);
        if (this.state.mobileView) {
          return (
            <div key={node.drupal_internal__id*10} className={ (this.state.hover && (this.state.hoverId === node.drupal_internal__id)) ? "submenu-open" : ""}>
                {this.renderLink(node)}
                <i onClick={() => this.handleClick(node.drupal_internal__id)}></i>
                <div className={ (this.state.hover && (this.state.hoverId === node.drupal_internal__id)) ? "children open" : "children"}>
                    {children.map((item, key) => this.renderLink(item, key, children.length))}
                </div>
                
            </div>
            );
        }
        return (
            <span key={node.drupal_internal__id*10} onMouseLeave={this.hoverOff} className={ (this.state.hover && (this.state.hoverId === node.drupal_internal__id)) ? "submenu-open" : ""}>
                {this.renderLink(node)}
                <div className={ (this.state.hover && (this.state.hoverId === node.drupal_internal__id)) ? "children open" : "children"}>
                    {children.map((item, key) => this.renderLink(item, key, children.length))}
                </div>
            </span>
        )
    }

    /**
     * @description Render menu
     * @param edges
     * @returns {*}
     */
    renderMenu(edges) {
        const tree = this.convertToTree(edges).sort((a, b) => a.weight - b.weight);
        this.props.updateMainMenuList(tree.map(node => this.renderLinksWithChildren(node)))
        return (
            <div>
                <nav className="nav" onBlur={(e) => this.handleBlur(e)}>
                    {tree.map(node => this.renderLinksWithChildren(node))}
                </nav>
                <div className="mobile-menu">
                    <HamburgerMenu navigation={tree.map(node => this.renderLinksWithChildren(node))} />
                </div>
            </div>
        )
    }

    /**
     * @description Render menu
     * @returns {*}
     */
    render() {
        return (
            <StaticQuery
                query={graphql`
                            query MainNavQuery {
                              allMenuLinkContentMenuLinkContent(sort: {order: ASC, fields: weight}, filter: { menu_name: {eq: "main"}}) {
                                edges {
                                  node {
                                    id
                                    title
                                    alias
                                    weight
                                    
                                    drupal_internal__id
                                    parent_drupal_internal__id
                                    menu_name
                                  }
                                }
                              }
                            }
                        `}
                render={data => (
                    <>
                        <div className="nav-scroller" role="navigation" aria-label="Main navigation">
                            {this.renderMenu(data.allMenuLinkContentMenuLinkContent.edges)}
                        </div>
                    </>
                )}
            />
        )
    }
};

const mapDispatchToProps = dispatch => {
  return { updateMainMenuList: (data) => dispatch({ type: types.SET_MAIN_MENU_LIST, data }) }
}

export default connect(null, mapDispatchToProps)(MainMenu)