import React from 'react';
import FooterMenu from './footermenu';
import { 
    FaFacebook, 
    FaTwitter, 
    FaLinkedin, 
    FaYoutube, 
    FaInstagram 
} from 'react-icons/fa';

import bicycle from '../images/footer/girl-bike.svg';
import building from '../images/footer/tower-tree.svg';
import LegalMenu from './legal-menu';

const Footer = () => (
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-md-7 col-lg-8 offset-xl-1">
                    <p className="footer-legal">The Common Application</p>
                </div>
            </div>
            <div className="row">
                <FooterMenu />
                <div className="col-sm-5 col-lg-4 col-xl-3">
                    <p className="connect-text text-white">Connect with <span className="common-link">#commonapp</span></p>
                        <ul className="social-icons">
                            <li><a href="https://www.facebook.com/commonapp" aria-label="Visit us on facebook" target="_blank" rel="noopener noreferrer"><FaFacebook title={"Facebook icon"} /></a></li>
                            <li><a href="https://twitter.com/CommonApp" aria-label="Visit us on twitter" target="_blank" rel="noopener noreferrer"><FaTwitter title={"Twitter icon"} /></a></li>
                            <li><a href="https://www.linkedin.com/company/the-common-application/" aria-label="Visit us on linkedin" target="_blank" rel="noopener noreferrer"><FaLinkedin title={"LinkedIn icon"} /></a></li>
                            <li><a href="https://www.youtube.com/user/CommonAppMedia" aria-label="Visit us on youtube" target="_blank" rel="noopener noreferrer"><FaYoutube title={"Youtube icon"} /></a></li>
                            <li><a href="https://www.instagram.com/commonapp" aria-label="Visit us on instagram" target="_blank" rel="noopener noreferrer"><FaInstagram title={"Instagram icon"} /></a></li>
                        </ul>
                </div>
            </div>
        </div>
        <section className="footer-nav">
            <div className="container">
                <div className="row">
                    <div className="col-xl-11 offset-xl-1">
                        <img className="bicycle-logo" src={bicycle} alt=""/>
                        <img className="building-logo" src={building} alt=""/>
                        <LegalMenu />
                        <div>
                        <span className="copyright-text">
                        <span className="reg-mark">&reg;</span>&nbsp;&nbsp;{new Date().getFullYear()} The Common Application, Inc
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </footer>
);

export default Footer;